const initialState = {
    isLoading: false,
    environmentId: 0,
    conversationId: 0,
    conversationHistory: [],
    chatHistory: [],
    isFileUploaded: false,
    fileInfo: null,
    fields: null
}

const chatReducer = (state = initialState, action) => {
    switch (action.type) {
        case "SET_ISLOADING":
            return {
                ...state,
                isLoading: action.payload
            };

        case "SET_ENVIRONMENTID":
            return {
                ...state,
                environmentId: action.payload
            };

        case "GET_CONVERSATIONLIST":
            return {
                ...state,
                conversationHistory: action.payload
            };

        case "CREATE_CONVERSATION":
            const req = action.payload.req;
            const res = action.payload.res;

            if (req.status === 'P') {
                const conHistory = {
                    convo_id: req.convoID,
                    title: req.user_prompt,
                    lastUpdated: req.timestamp,
                    pinned: req.pinned
                }

                return {
                    ...state,
                    chatHistory: [req],
                    conversationHistory: [conHistory, ...state.conversationHistory],
                    conversationId: req.convoID,
                    fields: {
                        Region: req.region,
                        LOB: req.lob
                    }
                };
            }
            else {
                return {
                    ...state,
                    chatHistory: [res]
                };
            }

        case "UPDATE_CONVERSATIONID":
            let targetIndex = state.conversationHistory.find(x => x.convo_id === action.payload.key);
            targetIndex.convo_id = action.payload.conID;
            state.conversationId = action.payload.conID;
            return {
                ...state
            };

        case "SEND_MESSAGE":
            const request = action.payload.req;
            const response = action.payload.res;

            if (request.status === 'P') {
                return {
                    ...state,
                    chatHistory: [...state.chatHistory, action.payload.req]
                };
            }
            else {
                const index = state.chatHistory.findIndex(x => x.key === request.key);
                state.chatHistory[index] = response;

                return {
                    ...state
                };
            }

        case "DELETE_CONVERSATION":
            const history = state.conversationHistory.filter(x => x.convo_id !== action.payload.ConvoID);
            return {
                ...state,
                conversationHistory: history,
                conversationId: 0,
                chatHistory: []
            };

        case 'UPDATE_TITLE':
            let targetResult = state.conversationHistory.find(x => x.convo_id === action.payload.convoID);
            targetResult.title = action.payload.convoTitle;
            targetResult.lastUpdated = action.payload.lastUpdated;
            return {
                ...state
            };

        case "GET_CHATHISTORY":
            return {
                ...state,
                chatHistory: action.payload.chatHistory,
                fields: action.payload.fields,
                isLoading: false
            };

        case 'UPDATE_PIN':
            let target = state.conversationHistory.find(x => x.convo_id === action.payload.convoID);
            target.pinned = action.payload.pinValue;
            return {
                ...state,
            };


        case "UPDATE_THUMB":
            let data = state.chatHistory.find(x => x.message_id === action.payload.msgId);
            data.thumb_feedback = action.payload.value;
            return {
                ...state,
            };

        case "SET_CONVERSATIONID":
            return {
                ...state,
                conversationId: action.payload,
                chatHistory: []
            };






        case "ADD_CONVEERSATION_HISORY": {
            const req = action.payload;
            return {
                ...state,
                conversationHistory: [req, ...state.conversationHistory],
                conversationId: req.convoID
            };
        }

        case "ISDISABLE_UPLOADICON": {
            const req = action.payload;
            return {
                ...state,
                isFileUploaded: req.isDocumentUploaded,
                fileInfo: req.isDocumentUploaded ? req : null
            };
        }

        case "UPDATE_PAPAERCLIPICON": {
            const index = state.conversationHistory.findIndex(x => x.convoID === action.payload);

            state.conversationHistory[index].isFileUploaded = 1;
            return {
                ...state
            };
        }

        case "REJECT_DOCUMENT": {
            const index = state.conversationHistory.findIndex(x => x.convoID === action.payload);

            state.conversationHistory[index].isFileUploaded = 0;
            state.isFileUploaded = false;
            state.fileInfo = null;
            return {
                ...state
            };
        }

        default:
            return state;
    }
}

export default chatReducer